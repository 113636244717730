<template>
  <div>
    <div class="user_list">
      <div class="card" v-for="(item, i) in UserData" :key="i">
        <el-row type="flex" style="min-height:65px" align="center">
          <el-col :span="4">
            <div class="img">
              <el-avatar :size="50" :src="item.head_img" v-if="item.head_img">
              </el-avatar>
              <el-avatar v-else :size="50" src="">
              </el-avatar>
            </div>
          </el-col>
          <el-col :span="19" :offset="1">
            <div class="name">
              <span style="font-size:16px">{{ item.user_name }}</span>
              <span>{{ item.is_wenli == 1 ? " 文科" : " 理科" }}</span>
              <span>{{ item.is_sex == 1 ? " 男" : " 女" }}</span>
              <span>{{ item.school }}</span> <span>{{ item.score }}分</span>
            </div>
            <div v-for="(m, k) in ExpertList" :key="k">
              <span class="teacher" v-if="m.id == item.parent_id">所属导师：{{ m.name }}</span>
            </div>
            <div class="btn">
              <span @click="toUserData(item)">志愿表</span>
              <span @click="editStatus(item.user_id)">发送给其他导师</span>
            </div>
          </el-col>
        </el-row>
        <el-row style="border-top:1px solid gray;margin-top:9px">
          <div style="text-align: center;font-size:14px;margin-top:3px" v-if="item.status == 1">
            <count_down :create_time=item.create_time> </count_down>
          </div>
          <div style="text-align: center;font-size:14px;margin-top:3px" v-if="item.status == 2">
            已回复
          </div>
        </el-row>
      </div>
    </div>

    <el-dialog :visible.sync="dialogVisibles" title="发送给其他导师" width="700px">
      <el-radio-group v-model="ExpertName">
        <el-radio :label="item.id" :value="item.id" v-for="(item, k) in ExpertList" :key="k">{{ item.name }}</el-radio>
      </el-radio-group>

      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisibles = false">取 消</el-button>
        <el-button type="primary" @click="changeParent()">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import count_down from "./CountDown";
export default {
  name: "",
  props: ["UserData"],
  data() {
    return {
      dialogVisible: false,
      dialogVisibles: false,
      ExpertName: "",
      ExpertList: [],
      type: 1,
      user_id: "",
      form: {},
    };
  },
  components: {
    count_down,
  },
  created() {
    this.ExpertInfo = JSON.parse(localStorage.getItem("ExpertInfo"));
    const data = {
      parent_id: this.ExpertInfo.id,
    };
    this.$fecth.post("expert/getData", data).then((res) => {
      const { code, data } = res;
      if (code == 200) {
        this.ExpertList = data.lists;
      }
    });
  },

  methods: {
    toUserData(item) {
      console.log(item);
      this.$router.push({
        path: "/reviewed_info",
        query: {
          user_id: item.user_id,
          report_id: item.id,
          score: item.score,
          batch: item.batch,
          is_wenli: item.is_wenli,
        },
      });
    },

    close() {
      this.dialogVisible = false;
      this.$parent.getList();
    },
    editStatus(id) {
      this.user_id = id;
      this.dialogVisibles = true;
    },
    changeParent() {
      let data = {
        parent_id: this.ExpertName,
        user_id: this.user_id,
      };
      this.$fecth.post("reviewed/editParent", data).then((res) => {
        const { code } = res;
        if (code == 200) {
          this.dialogVisibles = false;
          this.$parent.getList();
        }
      });
    },
  },
};
</script>

<style scoped lang='less'>
.user_list {
  .card {
    background: white;
    margin-bottom: 12px;
    display: inline-block;
    vertical-align: top;
    height: 110px;
    margin-right: 5px;
    padding: 10px;
    width: 32.7%;

    .name {
      line-height: 150%;
      font-size: 12px;
    }

    .btn {
      margin-top: 10px;

      span {
        border-radius: 30px;
        border: 1px solid #e3e3e3;
        padding: 1px 7px;
        margin-right: 7px;
        font-size: 14px;
      }
    }
  }

  .teacher {
    font-size: 10px;
    border: 1px solid #02e44d;
    display: inline;
    padding: 1px 10px;
    border-radius: 10px;
    color: #02e44d;
  }
}
</style>
