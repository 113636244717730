<template>
  <div class="count_down">
    <!-- <span>{{ time.day }}</span> 天 -->
    <span>{{ time.hou }}</span>时<span>{{ time.min }}</span>分<span>{{ time.sec
    }}</span>秒
  </div>
</template>

<script>
export default {
  name: "",
  data() {
    return {
      actEndTime: "",
      time: {
        day: '',
        hou: '',
        min: '',
        sec: '',
      },
    };
  },
  props: ['create_time'],
  created() {
    this.actEndTime = this.create_time
    this.countDown();
  },
  methods: {
    //倒计时
    countDown() {
      this.getTime()
      setInterval(() => {
        this.getTime();
      }, 1000);
    },
    getTime() {
      const newTime = new Date().getTime(); // 对结束时间进行处理渲染到页面
      const endTime = new Date(this.actEndTime).getTime();
      let time = (endTime - newTime) / 1000; // 获取天、时、分、秒
      time = time + (60 * 60 * 24)
      const day = parseInt(time / (60 * 60 * 24));
      const hou = parseInt((time % (60 * 60 * 24)) / 3600);
      const min = parseInt(((time % (60 * 60 * 24)) % 3600) / 60);
      const sec = parseInt(((time % (60 * 60 * 24)) % 3600) % 60);
      this.time.day = this.timeFormat(day);
      this.time.hou = this.timeFormat(hou);
      this.time.min = this.timeFormat(min);
      this.time.sec = this.timeFormat(sec);
    },
    timeFormat(param) {
      return param < 10 ? param : param;
    },
  },
};
</script>

<style scoped lang='less'>
.count_down {
  color: red;
}
</style>
